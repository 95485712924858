import { saveAs } from 'file-saver'

export default {
  methods:{
    async downloadProfessionCard(unit) {
      try {
        unit.isLoading = true
        let response = await this.$axios.get(`/jh-api/lists/work-unit/card/${unit.urlSlug}`,{
          responseType: 'blob'
        })
        const blob = new Blob([response.data], {
          type: 'application/pdf'
        })
        saveAs(blob, `Jobhub-Profese-${unit.title}.pdf`)
      } catch {
        //use only finally
      } finally {
        unit.isLoading = false
      }
    },
  }
}
