export function getEnumAsOptions(Enum) {
  let options = []
  for (let option of Object.keys(Enum)) {
    options.push(Enum[option])
  }
  return options
}

export const RIASEC = {
  'R': { value: 'R', title: 'Prakticko-technický' },
  'I': { value: 'I', title: 'Intelektuálně-výzkumný' },
  'A': { value: 'A', title: 'Umělecko-jazykový' },
  'S': { value: 'S', title: 'Sociální' },
  'E': { value: 'E', title: 'Podnikatelský' },
  'C': { value: 'C', title: 'Administrativně-správní' },
}

export const BASO = {
  11: { value: 11, title: 'Matematické obory' },
  12: { value: 12, title: 'Geologické obory' },
  13: { value: 13, title: 'Geografické obory' },
  14: { value: 14, title: 'Chemické obory' },
  15: { value: 15, title: 'Biologické obory' },
  16: { value: 16, title: 'Ekologie a ochrana životního prostředí' },
  17: { value: 17, title: 'Fyzikální obory' },
  18: { value: 18, title: 'Informatické obory' },
  21: { value: 21, title: 'Hornictví a hornická geologie, hutnictví a slévárenství' },
  23: { value: 23, title: 'Strojírenství a strojírenská výroba' },
  26: { value: 26, title: 'Elektrotechnika, telekomunikační a výpočetní technika' },
  28: { value: 28, title: 'Technická chemie a chemie silikátů' },
  29: { value: 29, title: 'Potravinářství a potravinářská chemie' },
  31: { value: 31, title: 'Textilní výroba a oděvnictví' },
  32: { value: 32, title: 'Kožedělná a obuvnická výroba a zpracování plastů' },
  33: { value: 33, title: 'Zpracování dřeva a výroba hudebních nástrojů' },
  34: { value: 34, title: 'Polygrafie, zpracování papíru, filmu a fotografie' },
  35: { value: 35, title: 'Architektura' },
  36: { value: 36, title: 'Stavebnictví, geodézie a kartografie' },
  37: { value: 37, title: 'Doprava a spoje' },
  39: { value: 39, title: 'Speciální a interdisciplinární obory' },
  41: { value: 41, title: 'Zemědělství a lesnictví' },
  43: { value: 43, title: 'Veterinářství a veterinární prevence' },
  51: { value: 51, title: 'Lékařské vědy' },
  52: { value: 52, title: 'Farmaceutické vědy' },
  53: { value: 53, title: 'Zdravotnictví' },
  61: { value: 61, title: 'Filozofie, teologie' },
  62: { value: 62, title: 'Ekonomie' },
  63: { value: 63, title: 'Ekonomika a administrativa' },
  64: { value: 64, title: 'Podnikání v oborech, odvětví' },
  65: { value: 65, title: 'Gastronomie, hotelnictví a turismus' },
  66: { value: 66, title: 'Obchod' },
  67: { value: 67, title: 'Sociální vědy' },
  68: { value: 68, title: 'Právo, právní a veřejnosprávní činnost' },
  69: { value: 69, title: 'Osobní a provozní služby' },
  71: { value: 71, title: 'Obory z oblasti historie' },
  72: { value: 72, title: 'Publicistika, knihovnictví a informatika' },
  73: { value: 73, title: 'Filologické vědy' },
  74: { value: 74, title: 'Tělesná kultura, tělovýchova a sport' },
  75: { value: 75, title: 'Pedagogika, učitelství a sociální péče' },
  77: { value: 77, title: 'Obory z oblasti psychologie' },
  78: { value: 78, title: 'Obecně odborná příprava' },
  79: { value: 79, title: 'Obecná příprava' },
  81: { value: 81, title: 'Teorie a dějiny umění' },
  82: { value: 82, title: 'Umění a užité umění' },
  91: { value: 91, title: 'Teorie vojenského umění' },
  95: { value: 95, title: 'Vojenské zdravotnictví' },
}
export const BADV = {
  'A': { value: 'A', title: 'Bez vzdělání' },
  'B': { value: 'B', title: 'Neúplné základní vzdělání' },
  'C': { value: 'C', title: 'Základní vzdělání' },
  'D': { value: 'D', title: 'Nižší střední vzdělání' },
  'E': { value: 'E', title: 'Nižší střední odborné vzdělání' },
  'H': { value: 'H', title: 'Střední odborné vzdělání s výučním listem' },
  'J': { value: 'J', title: 'Střední nebo střední odborné vzdělání bez maturity i výučního listu' },
  'K': { value: 'K', title: 'Úplné střední všeobecné vzdělání' },
  'L': { value: 'L', title: 'Úplné střední odborné vzdělání s vyučením i maturitou' },
  'M': { value: 'M', title: 'Úplné střední odborné vzdělání s maturitou (bez vyučení)' },
  'N': { value: 'N', title: 'Vyšší odborné vzdělání' },
  'P': { value: 'P', title: 'Vyšší odborné vzdělání v konzervatoři' },
  'R': { value: 'R', title: 'Vysokoškolské bakalářské vzdělání' },
  'T': { value: 'T', title: 'Vysokoškolské magisterské vzdělání' },
  'V': { value: 'V', title: 'Vysokoškolské doktorské vzdělání' },
}
export const LanguageDomain = { 1: { value: 1, title: 'Technicke' }, 2: { value: 2, title: 'Zdravotnické' }, 3: { value: 3, title: 'Obchodní' }, }
export const DepartmentGroup = {
  1: { value: 1, title: 'Jednoduché služby (bez větších nároků)' },
  2: { value: 2, title: 'Složité služby (nutná kvalifikace a intelekt)' },
  3: { value: 3, title: 'Průmysl, výroba' },
  4: { value: 4, title: 'Zemědělství, příroda a těžba' },
}
export const RoleGroup = {
  1: { value: 1, title: 'Externí, vztahy s okolím, čí péče o ně (zákazník, klient, stát, veřejnost)' },
  2: { value: 2, title: 'Lidské zdroje' },
  3: { value: 3, title: 'Administrativa a infrastruktura organizace, interní zákazník' },
  4: { value: 4, title: 'Kreativa, design a umění' },
  5: { value: 5, title: 'Řízení lidí a procesů' },
  6: { value: 6, title: 'Tok zásob a zboží' },
  7: { value: 7, title: 'Výroba a produkce, stroje a technologie' },
  8: { value: 8, title: 'IT a analýzy dat' },
  9: { value: 9, title: 'Výzkum a vývoj' },
}
export const LegislativeRequirementGroup = {
  1: { value: 1, title: 'Těžba surovin a výroba eneregie' },
  2: { value: 2, title: 'Architektura a stavebnictví' },
  3: { value: 3, title: 'Svařování' },
  4: { value: 4, title: 'Činnosti na technických zařízeních' },
  5: { value: 5, title: 'Pozemní doprava' },
  6: { value: 6, title: 'Letecká a lodní doprava' },
  7: { value: 7, title: 'Právo, ekonomika, obchod, veřejná správa' },
  8: { value: 8, title: 'Zdravotnictví' },
  9: { value: 9, title: 'Ostatní' },
}
export const PractiseRegulationGroup = {
  1: { value: 1, title: 'Právo, daně, audit a poradenství' },
  2: { value: 2, title: 'Těžba, hornictví, geologie' },
  3: { value: 3, title: 'Bezpečnost a ochrana zdraví při práci' },
  4: { value: 4, title: 'Elektrotechnika' },
  5: { value: 5, title: 'Ostatní' },
  6: { value: 6, title: 'Architektura, stavebnictví' },
  7: { value: 7, title: 'Vzdělávání' },
  8: { value: 8, title: 'Zdravotnictví' },
  9: { value: 9, title: 'Bezpečnost a obrana' },
  10: { value: 10, title: 'Doprava, přeprava, logistika' },
  11: { value: 11, title: 'Energetika, hospodaření s energiemi' },
  12: { value: 12, title: 'IT a telekomunikace' },
}
export const CompetenceGroup = {
  1: { value: 1, title: 'Manuální' },
  2: { value: 2, title: 'Technické' },
  3: { value: 3, title: 'Interakce s lidmi /Dovednosti k práci s lidmi' },
  4: { value: 4, title: 'Práce s informacemi, myšlení a přehled' },
  5: { value: 5, title: 'Obranna, ochrana a pomoc ostatním' },
  6: { value: 6, title: 'Kreativní' },
  7: { value: 7, title: 'Řízení lidí' },
  8: { value: 8, title: 'Odborné znalosti' },
}
export const KeywordsGroupEnum = { 1: { value: 1, title: 'Technické' }, 2: { value: 2, title: 'Humanitní' }, }
export const KeywordsEnum = {
  1: { value: 1, title: 'Navrhovat nové postupy a řešení' },
  2: { value: 2, title: 'Fyzicky vyrábět (něco hmatatelného)' },
  3: { value: 3, title: 'Měřit, kontrolovat, hodnotit' },
  4: { value: 4, title: 'Analyzovat a zkoumat' },
  5: { value: 5, title: 'Plánovat, projektovat' },
  6: { value: 6, title: 'Opravovat a udržovat' },
  7: { value: 7, title: 'Pracovat s daty, dělat výpočty' },
  8: { value: 8, title: 'Kompletovat zboží, služby' },
  9: { value: 9, title: 'Obsluhovat stroje a technologie' },
  10: { value: 10, title: 'Programovat a vyvíjet' },
  11: { value: 11, title: 'Komunikovat s lidmi' },
  12: { value: 12, title: 'Ovlivňovat druhé a vyjednávat' },
  13: { value: 13, title: 'Pomáhat lidem' },
  14: { value: 14, title: 'Vyřizovat a zařizovat' },
  15: { value: 15, title: 'Prodávat a obchodovat' },
  16: { value: 16, title: 'Pěstovat rostliny či chovat zvířata' },
  17: { value: 17, title: 'Účtovat, pracovat s doklady' },
  18: { value: 18, title: 'Školit, vzdělávat, učit' },
  19: { value: 19, title: 'Navrhovat a kreativně tvořit' },
  20: { value: 20, title: 'Podporovat a poskytovat zázemí druhým' },
  21: { value: 21, title: 'Řídit druhé' },
  22: { value: 22, title: 'Léčit a uzdravovat' },
  23: { value: 23, title: 'Práce s financemi či jejich řízení' },
}
export const HealthConditionGroup = {
  1: { value: 1, title: 'Alergie' },
  2: { value: 2, title: 'Fobie' },
  3: { value: 3, title: 'Nervové' },
  4: { value: 4, title: 'Problémy dýchacích cest' },
  5: { value: 5, title: 'Problémy jiných orgánů, imunity a další' },
  6: { value: 6, title: 'Problémy oběhové soustavy' },
  7: { value: 7, title: 'Problémy pohybového aparátu' },
  8: { value: 8, title: 'Problémy snášenlivosti s látkami a prostředím' },
  9: { value: 9, title: 'Smyslové postižení' },
  10: { value: 10, title: 'Závislosti, deprese, psych. potíže' },
  11: { value: 11, title: 'Zdravotní způsobilost k výkonu vojenské služby' },
}
export const CZNUTS3 = {
  1: { value: 1, title: 'Česká republika' },
  3: { value: 3, title: 'Hlavní město Praha' },
  5: { value: 5, title: 'Středočeský kraj' },
  7: { value: 7, title: 'Jihočeský kraj' },
  8: { value: 8, title: 'Plzeňský kraj' },
  10: { value: 10, title: 'Karlovarský kraj' },
  11: { value: 11, title: 'Ústecký kraj' },
  13: { value: 13, title: 'Liberecký kraj' },
  14: { value: 14, title: 'Královéhradecký kraj' },
  15: { value: 15, title: 'Pardubický kraj' },
  17: { value: 17, title: 'Kraj Vysočina' },
  18: { value: 18, title: 'Jihomoravský kraj' },
  20: { value: 20, title: 'Olomoucký kraj' },
  21: { value: 21, title: 'Zlínský kraj' },
  23: { value: 23, title: 'Moravskoslezský kraj' },
}
