<template>
  <v-layout
    row
    wrap
  >
    <v-flex md3 :xl2="ownPage" :offset-xl1="ownPage">
      <v-card class="pa-3">
        <v-card-title class="pa-0">
          <h3 class="mb-0">
            Filtrovat volná místa
          </h3>
        </v-card-title>
        <v-form
          ref="form"
          lazy-validation
          class="text-xs-center"
        >
          <v-select
            v-model="model.region"
            :disabled="isLoading"
            :items="getEnumAsOptions(Enum.CZNUTS3)"
            label="Region"
            item-text="title"
            menu-props="offsetY"
            item-value="value"
            clearable
            solo
            flat
            outline
          />
          <v-slider
            v-model="model.wage"
            :disabled="isLoading"
            class="mb-4"
            label="Minimální plat"
            always-dirty
            step="1000"
            min="0"
            max="100000"
            persistent-hint
            :hint="`${model.wage} Kč`"
          ></v-slider>
          <v-select
            v-model="model.minEducation"
            :disabled="isLoading"
            :items="getEnumAsOptions(Enum.BADV)"
            label="Dosažené vzdělání"
            item-text="title"
            menu-props="offsetY"
            item-value="value"
            clearable
            solo
            flat
            outline
          />
          <v-btn
            large
            :loading="isLoading"
            dark
            color="jhM4"
            @click="loadJobs"
          >
            Fitrovat
          </v-btn>
        </v-form>
      </v-card>
    </v-flex>
    <v-flex
      xs12
      md9
      :xl8="ownPage"
    >
      <template v-if="jobs.length > 0">
        <v-slide-y-transition group>
          <profession
            v-for="(item, index) in jobs"
            :key="`${index}_prof`"
            :title="item.name"
            :subtitle="getSubTitle(item)"
            color="jh-white"
            icon-color="jhGrey"
            btn-color="jh-m4"
            icon="flaticon-job"
            :url="`https://www.uradprace.cz/web/cz/volna-mista-v-cr#/volna-mista-detail/${item.uid}`"
          >
            <div
              slot="pre-button"
              class="d-inline-block"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    :loading="item.loading"
                    v-on="on"
                    @click="expandJob(item)"
                  >
                    <v-icon color="jh-m4">
                      {{
                        item.expanded
                          ? 'flaticon-expand-1'
                          : 'flaticon-forward'
                      }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Zobrazit povolání</span>
              </v-tooltip>
            </div>
            <v-slide-y-transition
              v-if="item.expanded && !item.loading"
              slot="expand"
              group
            >
              <template v-for="(unit, unitIndex) in item.units">
                <v-divider :key="`${unitIndex}_unit_div`"></v-divider>
                <v-card-text
                  :key="`${unitIndex}_unit_cont`"
                  class="py-1"
                >
                  <v-layout wrap>
                    <v-flex
                      shrink
                      align-self-center
                    >
                      <slot name="left-icon">
                        <v-icon
                          class="pl-2"
                          color="jhGrey"
                        >
                          flaticon-job
                        </v-icon>
                      </slot>
                    </v-flex>
                    <v-flex
                      grow
                      align-self-center
                    >
                      <h4 class="subheading font-weight-light">
                        {{ unit.title }}
                      </h4>
                    </v-flex>
                    <v-flex
                      :shrink="breakpoint.smAndUp"
                      align-self-center
                    >
                      <v-btn
                        large
                        outline
                        color="jh-green"
                        class="mx-0"
                        dark
                        @click="openNSP(unit.urlSlug)"
                      >
                        Detail NSP
                      </v-btn>
                      <v-btn
                        v-if="unit.isMain"
                        large
                        outline
                        color="jh-green"
                        class="mx-0"
                        dark
                        @click="downloadProfessionCard(unit)"
                      >
                        <v-icon>flaticon-download</v-icon>&nbsp;&nbsp;Karta povolání
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </template>
              <template v-if="item.units.length === 0">
                <v-divider key="404Div"></v-divider>
                <v-card-text
                  key="404Text"
                  class="py-1"
                >
                  <v-layout wrap>
                    <v-flex
                      grow
                      align-self-center
                    >
                      <h4 class="subheading font-weight-light">
                        Bohužel dané nabídce práce neodpovídá, žádné povolaní.
                      </h4>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </template>
            </v-slide-y-transition>
          </profession>
        </v-slide-y-transition>
      </template>
      <profession
        v-else
        title="Pro zadanou filtraci nebyla nalezena žádná volná pracovní místa"
        color="jh-white"
        :show-icon="false"
        :show-button="false"
        :show-subtitle="false"
      ></profession>
      <profession
        v-if="showLoadMore"
        title="Načíst dalsí nabídky"
        style="cursor: pointer"
        color="jhM1"
        :show-icon="false"
        :show-button="false"
        :show-subtitle="false"
        @click.native="loadMoreJobs"
      ></profession>
    </v-flex>
  </v-layout>
</template>

<script>
import DownloadProfessionCardMixin from '~/components/DownloadProfessionCardMixin'
import Profession from '@/components/Card/Profession'
import * as Enum from '~/utils/Enum'
import {
  STATE as JOBS_STATE,
  MUTATIONS as JOB_MUTATIONS,
  ACTIONS as JOBS_ACTIONS,
  NAMESPACE as JOBS_NS
} from '~/store/jobs'

const DEFAULT_MODEL = {
  region: 1,
  wage: 7000,
  minEducation: null
}

export default {
  components: { Profession },
  mixins: [DownloadProfessionCardMixin],
  props: {
    ownPage: {
      // if own page, query is read and load more is available
      // parent page has to implement fetch method to populate store and watch querystring
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      Enum,
      model: {
        region: 1,
        wage: 7000,
        minEducation: null
      }
    }
  },
  computed: {
    showLoadMore() {
      if (this.jobs.length >= this.totalJobs) {
        return false
      }
      return this.ownPage && !this.isLoading && this.jobs.length > 0
    },
    totalJobs() {
      return this.$store.state[JOBS_NS][JOBS_STATE.TOTAL_JOBS]
    },
    jobs() {
      return this.$store.state[JOBS_NS][JOBS_STATE.JOBS]
    },
    isLoading() {
      return this.$store.state[JOBS_NS][JOBS_STATE.LOADING]
    },
    breakpoint() {
      if (this.isMounted) {
        return this.$vuetify.breakpoint
      }
      return this.$vuetify.breakpoint
    }
  },
  mounted() {
    if (!this.ownPage) {
      this.loadJobs()
    } else {
      let query = this.$route.query
      if (query.region !== undefined) {
        query.region = parseInt(query.region)
      }
      if (query.wage !== undefined) {
        query.wage = parseInt(query.wage)
      }
      this.$set(this, 'model', Object.assign(DEFAULT_MODEL, this.model, query))
    }
  },
  methods: {
    getEnumAsOptions: Enum.getEnumAsOptions,
    openNSP(slug) {
      let newWindow = window.open()
      newWindow.opener = null
      newWindow.location = `https://nsp.cz/jednotka-prace/${slug}`
    },
    getSubTitle(item) {
      let out = ''
      if (item.region !== null) {
        out += `${item.region.title}`
      }
      if (item.wage !== 0) {
        if (item.region !== null) {
          out += ', '
        }
        let wage = new Intl.NumberFormat('cs-CZ').format(item.wage)
        out += `${wage} ${item.wageType.title}`
      }
      return out
    },
    expandJob(job) {
      if (job.expanded === true) {
        this.$store.commit(`${JOBS_NS}/${JOB_MUTATIONS.TOGGLE_JOB}`, job.uid)
        return
      }
      this.$store.dispatch(`${JOBS_NS}/${JOBS_ACTIONS.LOAD_UNITS}`, {
        jobUid: job.uid,
        clear: true
      })
    },
    loadJobs() {
      if (this.ownPage) {
        this.$router.push({ query: this.model })
      } else {
        this.$store.dispatch(`${JOBS_NS}/${JOBS_ACTIONS.LOAD_JOBS}`, {
          query: this.model,
          clear: true
        })
      }
    },
    loadMoreJobs() {
      this.$store.dispatch(`${JOBS_NS}/${JOBS_ACTIONS.LOAD_JOBS}`, {
        query: this.model
      })
    }
  }
}
</script>
