export default {
  props: {
    btnDetailTitle: {
      type: String,
      default: 'Detail'
    },
    icon: {
      type: String,
      default: 'flaticon-job'
    },
    iconColor: {
      type: String,
      default: 'jh-white'
    },
    btnColor: {
      type: String,
      default: 'jhM1'
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    showButton: {
      type: Boolean,
      default: true
    },
    showSubtitle: {
      type: Boolean,
      default: true
    },
    to: { // router to
      type: [String, Object],
    },
    url: { // redirect to other website
      type: String,
    },
    outline: {
      type: Boolean
    },
    color: {
      type: String
    },
    transparent: {
      type: Boolean
    }
  },
  data() {
    return {
      isMounted: false,
    }
  },
  computed: {
    _showSubtitle() {
      if (this.showSubtitle === false) {
        return false
      }
      return this.subtitle !== null
    },
    breakpoint() {
      if (this.isMounted) {
        return this.$vuetify.breakpoint
      }
      return this.$vuetify.breakpoint
    }
  },
  mounted() {
    this.isMounted = true
  },
  methods: {
    link() {
      if (this.to !== undefined) {
        this.$router.push(this.to)
      }
      if (this.url !== undefined) {
        this.openWindow(this.url)
      }
    },
    openWindow(url) {
      let newWindow = window.open()
      newWindow.opener = null
      newWindow.location = url
    },
    renderContent(h) {
      return h('v-layout', {
        attrs: {
          wrap: this.breakpoint.mdAndDown,
          'align-center': true,
        }
      }, [

        this.showIcon === true ?
          h('v-flex',
            {
              staticClass: 'ml-2 text-xs-center',
              attrs: { shrink: this.breakpoint.smAndUp, xs12: this.breakpoint.xs }
            },
            [this.renderIcon(h)]
          )
          : null,

        this.renderTextContent(h),

        this.showButton === true ?
          h('v-flex',
            {
              staticClass: 'text-xs-center text-sm-right',
              attrs: { xs12: this.breakpoint.xs }
            }, [...this.renderButtons(h)]
          )
          : null,
      ])
    },
    renderIcon(h) {
      return this.$slots['left-icon'] !== undefined ? this.$slots['left-icon'] : h('v-icon', { attrs: { color: this.iconColor, large: true } }, [this.icon])
    },
    renderTextContent(h) {
      let style = {}
      if (this.breakpoint.smAndUp) {
        style = { 'max-width': '50%' }
      }

      return h('v-flex', { staticClass: 'text-xs-center text-sm-left', attrs: { grow: true, xs12: this.breakpoint.xs }, style }, [
        h('h4', { staticClass: 'subheading font-weight-light' }, [this.title]),
        this._showSubtitle === true ? h('p', { staticClass: 'mb-0' }, [this.subtitle]) : null
      ])
    },
    renderButtons(h) {
      return [
        this.$slots['pre-button'] !== undefined ? this.$slots['pre-button'] : null,
        this.$slots['button'] !== undefined ? this.$slots['button'] : h('v-btn', {
          staticClass: 'mx-0', attrs: {
            large: true,
            outline: true,
            color: this.btnColor,
            dark: true,
          },
          on:{
            click: ()=>{
              this.link()
            }
          },
        }, [this.btnDetailTitle]),
        this.$slots['post-button'] !== undefined ? this.$slots['post-button'] : null
      ]
    },
  },
  render(h) {
    let staticClass = 'mb-2 text-xs-left pa-2 v-card--btn-break'
    if (this.outline === true) {
      staticClass += ' v-card--outline'
    }
    if (this.transparent === true) {
      staticClass += ' transparent'
    }
    return h('v-card', {
      staticClass,
      attrs: {
        color: this.color
      },
    }, [
      this.renderContent(h),
      this.$slots.expand
    ])
  }
}
